<template>
  <b-card no-body class="p-3">
    <b-card-body class="invoice-padding pb-0">
        <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
              <div>
                <div class="logo-wrapper">
                  <logo />
                  <h3 class="text-secondary invoice-logo">
                    Daftar Default Coa
                  </h3>
                </div>
              </div>
        </div>
    </b-card-body>
     <!-- Spacer -->
<br>
<br>
    <b-card-body class="invoice-padding pt-0">
        <div v-for="(items, index) in viewLoop" :key="index">
        <b-form-input :hidden="true" size="sm" v-model="form[index].code" disabled/>
        <b-row class="invoice-spacing">
             <b-col
                  cols="12"
                  xl="12"
                  class="mb-lg-1"
             >
                <div v-if="index === 0" >
                     <h5 class="text-success invoice-logo">
                        {{ items.category }}     
                        <hr class="invoice-spacing">
                     </h5>
                </div>
                <div v-else>
                        <h5 class="text-success invoice-logo" v-if="items.category !== viewLoop[ index - 1 ].category">
                            {{ items.category }}      
                        <hr class="invoice-spacing">
                        </h5>
                </div>
                

             </b-col>
             <b-col
                  cols="12"
                  xl="4"
                  class="mb-lg-1"
             >
               <h6 class="text-secondary invoice-logo">
                    {{ items.name }}  
               </h6>
             </b-col>
              <b-col
                  cols="12"
                  xl="4"
                  class="mb-lg-1"
             >
                <v-select
                  id="account_name"
                  v-model="form[index].accountId"
                  :options="LOV.account"
                  :reduce="field => field.id"
                  label="account_name"
                />
             </b-col>
        </b-row>
        </div>
    </b-card-body>
   <div class="mt-3 d-flex flex-sm-column flex-md-row justify-content-end">
          <b-button
            variant="primary"
            type="submit"
            @click="handleSubmit"
          >
            <feather-icon
              icon="SaveIcon"
            />
            {{ $t('globalActions.update') }}
          </b-button>
        </div>
        
  </b-card>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import useHttp from '@/comp-functions/useHttp'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


import {
  BCard,
  BRow,
  BCol,
  BButton
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {

  components: {
    BCard,
    BRow,
    BCol,
    vSelect,
    BButton
  }, 
  setup () {

    const { 
        $get,
        toast,
        $put 
        } = useHttp()
    const LOV = ref({
      account: []
    })

    const viewLoop = ref({})

    const form = ref([])

  
     const getAccount = async () => {
      const { data } = await $get({ url: 'value/getCoa/coaLv2/'})
      LOV.value.account = data
    }

     const getViewLoop = async () => {
        const { data } = await $get({ url: 'master/setting-coa'})
        viewLoop.value = data

        viewLoop.value.map(prefix => {
        form.value.push({
          accountId: prefix.coa,
          code: prefix.code
        })
      })
     }

    onMounted(() => {
        getAccount()
        getViewLoop()
    })

    return {
        form,
        LOV,
        viewLoop,
        toast,
        $put
    }
  },
  methods: {
       handleSubmit () {
      const $swal = this.$swal

      $swal({
        title: `${ this.$t('confirm.updateThis') } ${ this.$t('apps.masterDataPrefix.singular.prefix') } ?`,
        text: `${ this.$t('info.ofCourse') } you still can delete or edit later.`,
        icon: 'question',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-flat-warning'
        }
      }).then(async ({ value }) => {
        if (value) {
          const callbackSuccess = () => {
            this.toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Success',
                icon: 'BellIcon',
                variant: 'success',
                text: `${this.$t('feedback.success')} ${this.$t('feedback.updated')} ${this.$t('apps.masterDataPrefix.singular.prefix')}.`
              }
            })
          }

          this.$put({
            data: this.form,
            url: 'master/setting-coa-update'
          }).then(() => callbackSuccess())
        }
          location.reload()
      })
    },
  }
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>